.mainContent {
  width: 400px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 150px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.deviceMain2_wrap {
  width: 99%!important;
}
.deviceMain2_wrap .mian_title2 {
  justify-content: space-between;
}
.deviceMain2_wrap .mian_title2 > div {
  display: flex;
}
.deviceMain2_wrap .flex_wrap {
  align-items: center;
  display: flex;
}
.deviceMain2_wrap .device_top {
  width: 100%;
  display: flex;
}
.deviceMain2_wrap .device_top .device_left {
  width: 54%;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  background: #ffffff;
  border-radius: 4px;
  margin: 10px;
}
.deviceMain2_wrap .device_top .device_left .device_title1 {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
}
.deviceMain2_wrap .device_top .device_left .device_title1 .font_text {
  font-size: 14px;
  margin-left: 12px;
}
.deviceMain2_wrap .device_top .device_left .device_title1 > p {
  color: #788CA1;
  flex-shrink: 0;
  margin: 0 20px 0 0;
}
.deviceMain2_wrap .device_top .device_left .device_title1 > p span {
  color: #26cc63;
  font-weight: 600;
}
.deviceMain2_wrap .device_top .device_left .device_black {
  display: flex;
  justify-content: space-around;
  margin: 25px 0 0;
}
.deviceMain2_wrap .device_top .device_left .device_black div {
  width: 22%;
  height: 180px;
  text-align: center;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  background: url("../../assets/img/item2.png");
  background-repeat: no-repeat;
  color: #ffffff;
}
.deviceMain2_wrap .device_top .device_left .device_black div .de_text1 {
  margin: 25px 0 5px;
}
.deviceMain2_wrap .device_top .device_left .device_black div .de_text {
  margin: 5px 0 10px;
}
.deviceMain2_wrap .device_top .device_left .device_black div .ed_font_color {
  font-size: 35px;
}
.deviceMain2_wrap .device_top .device_left .device_black div .de_red {
  font-weight: 600;
  font-size: 18px;
  color: #FD4848;
}
.deviceMain2_wrap .device_top .device_left .device_black div .de_green {
  font-size: 18px;
  font-weight: 600;
  color: #26cc63;
}
.deviceMain2_wrap .device_top .device_left .device_black div .de_font_gary {
  font-weight: 500;
}
.deviceMain2_wrap .device_top .device_right {
  width: 46%;
  height: 300px;
  display: flex;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  border-radius: 4px;
  background: #ffffff;
  justify-content: space-around;
  margin: 10px 10px 10px 0;
}
.deviceMain2_wrap .device_top .device_right > div {
  width: 50%;
  height: 100%;
}
.deviceMain2_wrap .device_top .device_right > div .device_black2 {
  width: 100%;
  height: calc(100% - 60px);
}
.deviceMain2_wrap .right_title_btn {
  display: flex;
  position: absolute;
  left: 100px;
  top: -10px;
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
}
.deviceMain2_wrap .right_title_btn .base_li {
  padding: 12px 0;
  text-align: center;
  margin: 0 0 0 20px;
  color: #788CA1;
  cursor: pointer;
  width: 160px;
}
.deviceMain2_wrap .right_title_btn .li_active {
  z-index: 10;
  color: #4261ED;
}
.deviceMain2_wrap .device_content_t {
  display: flex;
  width: calc(100% - 20px);
  height: 30%;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 0 10px 10px;
  flex-wrap: wrap;
}
.deviceMain2_wrap .device_content_t .block_33 {
  width: 33.33%;
  height: 100%;
  padding: 0 10px;
}
.deviceMain2_wrap .device_content_t .block_33 .device_title {
  display: flex;
  padding: 10px 15px;
  font-size: 15px;
  align-items: center;
}
.deviceMain2_wrap .device_content_t .block_33 .device_title .device_title_text1 {
  margin: 0  10px;
}
.deviceMain2_wrap .device_content_t .block_33 .device_title .device_title_text2 {
  font-size: 12px;
  flex-shrink: 0;
}
.deviceMain2_wrap .device_content_b {
  width: calc(100% - 20px);
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 0 10px 10px;
  height: 30%;
}
.deviceMain2_wrap .device_content_b .device_title {
  display: flex;
  padding: 10px 15px;
  font-size: 15px;
  align-items: center;
}
.deviceMain2_wrap .device_content_b .device_title .device_title_text1 {
  margin: 0  10px 0 0;
}
.deviceMain2_wrap .device_content_b .device_title .device_title_text2 {
  font-size: 12px;
}
.deviceMain2_wrap .device_content_b .device_title_text3 {
  color: #788CA1;
  font-weight: 500;
  margin: 0 0 0 50px;
  font-size: 14px;
}
.device_status_icon_bg {
  width: 22px;
  height: 22px;
}
.device_chart {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  margin: 15px 0 0;
}
.device_chart > div {
  width: 45%;
  height: 100%;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.color_base {
  color: #4261ED;
}
