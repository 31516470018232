
    @import "../../resource/common.less";
    @import "../../variables.less";
    .deviceMain2_wrap{
		width: 99%!important;
        // height:calc(100vh - 90px);
        // top: 90px;
        .mian_title2{
            justify-content: space-between;
            &>div{
                display: flex;
            }
        }
        .flex_wrap{
            align-items: center;
            display:flex;
        }
        .device_top{
            width: 100%;
            display: flex;
            .device_left{
                width:54%;
                box-shadow: 0 0 10px 1px @boxshadow;
                background: @contentBg;
                border-radius: 4px;
                margin: 10px;
                .device_title1{
                    display:flex;
                    align-items: center;
                    position:relative;
                    justify-content: space-between;
                    .font_text{
                        font-size: 14px;
                        margin-left: 12px;
                    }
                    &>p{
                        color:@colorGary;
                        flex-shrink: 0;
                        margin: 0 20px 0 0;
                        span{
                            color:@colorGreen;
                            font-weight: 600;
                        }
                    }
                }
                .device_black{
                    display:flex;
                    justify-content:space-around;
                    margin: 25px 0 0;
                    div{
                        width:22%;
                        height:180px;    text-align: center;
                        border-radius: 20px;
                        font-weight: 600;
                        font-size: 16px;
                        // @baseColor2: rgba(red(@baseColor), green(@baseColor), blue(@baseColor), 0.7);
                        // background: linear-gradient(to right, @baseColor, @baseColor2);
                        background: url("../../assets/img/item2.png");background-repeat: no-repeat;
                        color: #ffffff;
                        .de_text1{
                            margin: 25px 0 5px;
                        }
                        .de_text{
                            margin: 5px 0 10px;
                        }
                        .ed_font_color{
                            font-size: 35px;
                        }
                        .de_red{
                            font-weight: 600;
                            font-size: 18px;
                            color:@colorRed;
                        }
                        .de_green{
                            font-size: 18px;
                            font-weight: 600;
                            color:@colorGreen;
                        }
                        .de_font_gary{
                        font-weight: 500;
                        }
                    }
                }
            }
            .device_right{
                width:46%;
                height:300px;
                display:flex;
                box-shadow: 0 0 10px 1px @boxshadow;
                border-radius: 4px;
                background: @contentBg;
                justify-content:space-around;
                margin: 10px 10px 10px 0;
                &>div{
                    width: 50%;
                    height: 100%;
                    .device_black2{
                        width: 100%;
                        height:calc(100% - 60px);
                    }
                }
            }
        }
        .right_title_btn{
            display:flex;
            position: absolute;
            left: 100px;
            top: -10px;
            margin: 0;
            font-weight: 500;
            font-size: @fontSizeC;
            padding: 0;
            .base_li{
                padding: 12px 0;
                text-align:center;
                margin: 0 0 0 20px;
                color:@colorGary;
                cursor:pointer;
                width: 160px;
            }
            .li_active{
                // border-bottom: 2px solid @baseColor;
                z-index: 10;
                color: @baseColor;
            }
        }
        .device_content_t{
            display:flex;
            width: calc(100% - 20px);
            height: 30%;
            border-radius: 4px;
            background:@contentBg;
            box-shadow: 0 0 10px 1px @boxshadow;
            margin: 0 10px 10px;
            flex-wrap:wrap;
            .block_33{
                width: 33.33%;
                height: 100%;
                padding: 0 10px;
                .device_title {
                    display: flex;
                    padding: 10px 15px;
                    font-size: 15px;
                    align-items:center;
                    .device_title_text1{
                        margin: 0  10px;
                    }
                        .device_title_text2{
                        font-size: 12px;
                        flex-shrink: 0;
                    }
                }
            }
        }
        .device_content_b{
            width: calc(100% - 20px);
            border-radius: 4px;
            background:@contentBg;
            box-shadow: 0 0 10px 1px @boxshadow;
            margin: 0 10px 10px;
            height: 30%;
            .device_title{
                display: flex;
                padding: 10px 15px;
                font-size: 15px;
                align-items:center;
                .device_title_text1{
                    margin: 0  10px 0 0;
                }
                .device_title_text2{
                    font-size: 12px;
                }
            }
            .device_title_text3{
                color:@colorGary;
                font-weight: 500;
                margin: 0 0 0 50px;
                font-size:@fontSizeC;
            }
        }
    }
    .device_status_icon_bg{
        width: 22px;
        height: 22px;
    }
    .device_chart{
        width:100%;
        height:300px;
        display: flex;
        justify-content: space-around;
        margin: 15px 0 0;
        &>div{
            width: 45%;
            height: 100%;
        }
    }
    .left_click_btn{
        position:absolute;
        top: 48%;
        left: -18px;
        // background: linear-gradient(180deg, var(--themeColor)0%, var(--themeColor)100%);
        background: var(--themeColor);
        display:flex;
        justify-content: center;
        align-items: center;
        padding: 7px 0px;
        border-radius: 4px 0 0 4px;
        cursor:pointer;
        img{
            width: 18px;
            transform: rotate(180deg);

        }
    }
    .left_click_btn_active{
        transition: all 2s ease;
        img{
            transform: rotate(0deg);

        }
    }
    .color_base{
        color: @baseColor;
    }
